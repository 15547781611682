<template>

<div class="marketing">
	Advert
</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.marketing {
	width: 100%;
	height: 240px;
	line-height: 240px;
	color: #fff;
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	background-color: #1277D4;
	margin-bottom: 20px;
}

</style>
